//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { useNavigate } from "react-router-dom";
import { OnBoardingActions } from "../OnBoardingActions";
import Header from "../../header/simpleHeader";
import { Context } from "../../../common/context/Context";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import womanOnLaptop from "manpowergroup-cirrus/assets/images/womanOnLaptop.png";
import background from "manpowergroup-cirrus/assets/images/background.png";
import Footer from "../../../common/components/footer";
import AutoComplete from "../../../common/components/autoComplete/AutoComplete";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./index.css";
import LangHeader from "../../header/simpleHeader/LangHeader";

function ConfirmDetails() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const [screenLoader, setScreenLoader] = useState(false);
  const [signUpStates, setSignUpStates] = useState({
    firstName: {
      name: "FirstName",
      value: "",
      errorMsg: "",
      state: "",
    },
    lastName: {
      name: "LastName",
      value: "",
      errorMsg: "",
      state: "",
    },
    preferredName: {
      name: "PreferredName",
      value: "",
      errorMsg: "",
      state: "",
    },
    primaryEmail: {
      name: "Email",
      value: "",
      errorMsg: "",
      state: "",
    },
    alternativeEmail: {
      name: "AltEmail",
      value: "",
      errorMsg: "",
      state: "",
    },
    phone: {
      name: "Phone",
      value: "",
      errorMsg: "",
      state: false,
    },
    cityOfResidence: {
      name: "City",
      value: "",
      errorMsg: "",
      state: "",
    },
    timeZone: {
      name: "TimeZone",
      value: "",
      errorMsg: "",
      state: "",
    },
    languagePreference: {
      name: "Language",
      value: "",
      errorMsg: "",
      state: "",
    },
  });
  const [cmsContent, setCmsContent] = useState({
    largeImage: "",
    title: "",
    description: "",
    labels: [],
  });
  const [cmsServiceOffice, setCmsServiceOffice] = useState({
    title: "",
    description: "",
  });
  const [cityList, setCityList] = useState({
    text: "",
    suggestions: [],
    noData: false,
  });
  const [cityInput, setCityInput] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [languagePreferenceList, setLanguagePreferenceList] = useState([]);

  const [selectedServiceOffice, setSelectedServiceOffice] = useState();
  const [showYourDetails, setShowYourDetails] = useState(false);
  const [showServiceOffice, setShowServiceOffice] = useState(false);
  const [serviceOffices, setServiceOffices] = useState(
    JSON.parse(window.sessionStorage.getItem("setProgramDetails"))?.rightOffices
  );
  const [isErrorServiceOffice, setIsErrorServiceOffice] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCMSContent();
  }, []);

  useEffect(() => {
    let refTokenClaims = context.idTokenClaims;
    let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
    refSignUpStates.firstName.value = refTokenClaims?.firstName;
    refSignUpStates.lastName.value = refTokenClaims?.lastName;
    refSignUpStates.primaryEmail.value = refTokenClaims?.email;
    refSignUpStates.cityOfResidence.value = refTokenClaims?.country;
    setSignUpStates(refSignUpStates);

    let timeZoneOptions = [];
    let masterTimeZone = JSON.parse(
      window.sessionStorage.getItem("MasterTimezoneDetails")
    );
    let masterProgramDetails = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    masterTimeZone?.filter((item) => {
      if (item.key === "timezone") {
        item?.items?.map((tz) => {
          timeZoneOptions.push({
            name: tz.title,
            value: tz.id,
          });
        });
      }
    });
    if (masterProgramDetails?.programTimeZone !== "") {
      // let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
      let defaultObj = {
        name: masterProgramDetails?.programTimeZone
          ? masterProgramDetails?.programTimeZone
          : selectedServiceOffice?.programTimeZone,
        value: (masterProgramDetails?.programTimeZoneCode
          ? masterProgramDetails?.programTimeZoneCode
          : selectedServiceOffice?.programTimeZoneCode
        )?.toLowerCase(),
      };
      let fltrlan = timeZoneOptions?.filter((fl) => {
        return fl.value.toLowerCase() !== defaultObj.value;
      });
      fltrlan.unshift(defaultObj);
      refSignUpStates.timeZone.value = fltrlan[0].value;
      setSignUpStates(refSignUpStates);
      setTimeZoneList(fltrlan);
    } else {
      setTimeZoneList(timeZoneOptions);
    }

    setServiceOffices(
      serviceOffices?.map((option) => {
        return {
          ...option,
          label: option.name,
          labelText: option.name,
          value: option.name,
          defaultChecked: false,
        };
      })
    );
  }, []);

  useEffect(() => {
    fetchCMSContent();
  }, [context?.language]);

  const fetchCMSContent = async () => {
    let payload = {
      PageName: "OnboardingPages",
    };
    await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
      (res) => {
        if (res.status === 200) {
          window.sessionStorage.setItem(
            "setCmsOnBoadingContent",
            JSON.stringify(res.data)
          );
          let resData = JSON.parse(
            window.sessionStorage.getItem("setCmsOnBoadingContent")
          );
          if (Object.keys(resData).length !== 0) {
            let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
            let refCmsServiceOffice = JSON.parse(
              JSON.stringify(cmsServiceOffice)
            );
            setScreenLoader(true);
            resData.Pages?.filter((x) => {
              if (x.Key === "SignUpConfirmDetails") {
                let compName =
                  x.Components[
                    "SignUpConfirmDetails.OnboardingSignUpConfirmDetails"
                  ];
                refCmsContent.largeImage =
                  compName?.MediaData?.LargeImage?.ImageUrl;
                refCmsContent.title = compName?.Title;
                refCmsContent.description = x.Description;
                refCmsContent.labels = compName?.Labels;
              }
              if (x.Key === "OnboardingPages.ServiceOffice") {
                refCmsServiceOffice.title = x.Title;
                refCmsServiceOffice.description = x.Description;
              }
            });
            setCmsContent(refCmsContent);
            if (serviceOffices && serviceOffices?.length > 1) {
              setShowYourDetails(false);
              setShowServiceOffice(true);
            } else if (serviceOffices && serviceOffices?.length === 1) {
              setSelectedServiceOffice({
                value: serviceOffices[0].name,
                id: serviceOffices[0].id,
                programTimeZoneCode: serviceOffices[0].timeZoneId,
                programTimeZone: serviceOffices[0].timeZone,
              });
              setShowYourDetails(true);
              updateSatelliteCountryCode(serviceOffices[0].id);
            } else {
              setShowYourDetails(true);
              setShowServiceOffice(false);
            }

            setCmsServiceOffice(refCmsServiceOffice);
            setScreenLoader(false);
          }
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (refCmsContent?.labels.length > 0) {
      refCmsContent.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  // const getTimeZoneList = () => {
  //     let timeZoneOptions = []
  //     let masterTimeZone = JSON.parse(window.sessionStorage.getItem("MasterTimezoneDetails"))
  //     let masterProgramDetails = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))

  //     masterTimeZone?.filter((item) => {
  //         if (item.key === "timezone") {

  //             item?.items?.map((tz) => {

  //                 timeZoneOptions.push({
  //                     "name": tz.title,
  //                     "value": tz.id
  //                 })
  //             });
  //         }
  //     });
  //     if (masterProgramDetails?.programTimeZone !== "") {
  //         let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
  //         let defaultObj = {
  //             name: masterProgramDetails?.programTimeZone ? masterProgramDetails?.programTimeZone : selectedServiceOffice?.programTimeZone,
  //             value: (masterProgramDetails?.programTimeZoneCode ? masterProgramDetails?.programTimeZoneCode : selectedServiceOffice?.programTimeZoneCode)?.toLowerCase()
  //         }
  //         let fltrlan = timeZoneOptions?.filter((fl) => {
  //             return (fl.value).toLowerCase() !== defaultObj.value
  //         });

  //         fltrlan.unshift(defaultObj);
  //         refSignUpStates.timeZone.value = fltrlan[0].value
  //         setSignUpStates(refSignUpStates);
  //         setTimeZoneList(fltrlan)
  //     } else {
  //         setTimeZoneList(timeZoneOptions)
  //     }
  // }

  const getlanguagePreferenceList = () => {
    let language = [];
    let defaultLan = [];
    if (
      context?.programDetails.programLanguage &&
      context?.programDetails.programLanguageCode
    ) {
      let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
      JSON.parse(
        window.sessionStorage.getItem("setCmsOnBoadingContent")
      )?.MarketDetails[0]?.Supportedlanguage?.map((lang) => {
        if (lang.ISOCode === context?.programDetails?.programLanguageCode) {
          defaultLan.push({
            name: lang.LanguageName,
            value: lang.ISOCode,
          });
        } else {
          language.push({
            name: lang.LanguageName,
            value: lang.ISOCode,
          });
        }
      });
      let langList = [...defaultLan, ...language];
      refSignUpStates.languagePreference.value = langList[0]?.value;
      setSignUpStates(refSignUpStates);
      setLanguagePreferenceList(
        langList?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      JSON.parse(
        window.sessionStorage.getItem("setCmsOnBoadingContent")
      )[0]?.MarketSettings[0]?.Supportedlanguage?.map((lang) => {
        language.push({
          name: lang.LanguageName,
          value: lang.ISOCode,
        });
      });
      setLanguagePreferenceList(
        language?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    }
  };

  const updateSatelliteCountryCode = (rightOfficeId) => {
    const programDetails = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    const programRightOfficeCountryCode = programDetails.rightOffices.find(
      (rightOffice) => rightOffice.id == rightOfficeId
    )?.satelliteCountryCode;
    if (programDetails && programRightOfficeCountryCode) {
      programDetails.programRightOfficeCountryCode =
        programRightOfficeCountryCode;
    }
    window.sessionStorage.setItem(
      "setProgramDetails",
      JSON.stringify(programDetails)
    );
  };

  const formHandler = (event) => {
    let { name, value } = event.target;
    let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));

    if (name === refSignUpStates.preferredName.name) {
      if (value !== "") {
        refSignUpStates.preferredName.value = value;
        refSignUpStates.preferredName.state = "";
        refSignUpStates.preferredName.errorMsg = "";
        setSignUpStates(refSignUpStates);
      } else {
        refSignUpStates.preferredName.value = "";
        refSignUpStates.preferredName.state = "";
        refSignUpStates.preferredName.errorMsg = "";
        setSignUpStates(refSignUpStates);
      }
    }
    if (name === refSignUpStates.alternativeEmail.name) {
      if (value !== "") {
        refSignUpStates.alternativeEmail.value = value;
        refSignUpStates.alternativeEmail.state = "";
        refSignUpStates.alternativeEmail.errorMsg = "";
        setSignUpStates(refSignUpStates);
      } else {
        refSignUpStates.alternativeEmail.value = "";
        refSignUpStates.alternativeEmail.state = "";
        refSignUpStates.alternativeEmail.errorMsg = "";
        setSignUpStates(refSignUpStates);
      }
    }
    if (name === refSignUpStates.phone.name) {
      if (value !== "") {
        refSignUpStates.phone.value = value;
        refSignUpStates.phone.state = false;
        refSignUpStates.phone.errorMsg = "";
        setSignUpStates(refSignUpStates);
      } else {
        refSignUpStates.phone.value = "";
        refSignUpStates.phone.state = false;
        refSignUpStates.phone.errorMsg = "";
        setSignUpStates(refSignUpStates);
      }
    }
    if (name === refSignUpStates.cityOfResidence.name) {
      refSignUpStates.cityOfResidence.value = value;
      setSignUpStates(refSignUpStates);
    }
    if (name === refSignUpStates.timeZone.name) {
      refSignUpStates.timeZone.value = value;
      setSignUpStates(refSignUpStates);
    }
    if (name === refSignUpStates.languagePreference.name) {
      refSignUpStates.languagePreference.value = value;
      setSignUpStates(refSignUpStates);
    }
  };

  function validateEmail(email) {
    const Eval =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Eval.test(String(email)?.toLowerCase());
  }

  function validateMobile(mobile) {
    // let myString = parseFloat(mobile);
    let myString = mobile;
    var Pval = /^[0-9][0-9]{7,14}$/.test(myString);
    return Pval;
  }

  function validateAlphabets(text) {
    let regex = /^[a-zA-Z\s]*$/;
    if (regex.test(text)) {
      return true;
    } else {
      return false;
    }
  }

  const onFormSubmit = (event, submittedStepId) => {
    event.preventDefault();
    let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
    if (submittedStepId === "name") {
      let preferredNameFlag = true;
      let checkAlphabetsRegex = validateAlphabets(
        signUpStates.preferredName.value
      );
      if (signUpStates.preferredName.value !== "") {
        if (!checkAlphabetsRegex) {
          preferredNameFlag = false;
          refSignUpStates.preferredName.state = "error";
          refSignUpStates.preferredName.errorMsg = "Allowed characters only";
          setSignUpStates(refSignUpStates);
        } else {
          preferredNameFlag = true;
          refSignUpStates.preferredName.state = "";
          refSignUpStates.preferredName.errorMsg = "";
          setSignUpStates(refSignUpStates);
        }
      }
      if (preferredNameFlag) {
        return true;
      } else {
        return false;
      }
    }
    if (submittedStepId === "contact") {
      let emailFlag = true;
      let phonFlag = true;
      let checkEmailRegex = validateEmail(signUpStates.alternativeEmail.value);

      if (signUpStates.alternativeEmail.value !== "") {
        if (!checkEmailRegex) {
          emailFlag = false;
          refSignUpStates.alternativeEmail.state = "error";
          refSignUpStates.alternativeEmail.errorMsg =
            "Please enter a valid email";
          setSignUpStates(refSignUpStates);
        } else {
          emailFlag = true;
          refSignUpStates.alternativeEmail.state = "";
          refSignUpStates.alternativeEmail.errorMsg = "";
          setSignUpStates(refSignUpStates);
        }
      }
      if (signUpStates.phone.value !== "") {
        let value = signUpStates.phone.value.replace(/[^\d]/g, "");
        refSignUpStates.phone.value = value;
        if (value.length < 7 || !validateMobile(value)) {
          phonFlag = false;
          refSignUpStates.phone.state = true;
          refSignUpStates.phone.errorMsg = "Please enter a valid phone number";
          setSignUpStates(refSignUpStates);
        } else {
          phonFlag = true;
          refSignUpStates.phone.state = false;
          refSignUpStates.phone.errorMsg = "";
          setSignUpStates(refSignUpStates);
        }
      }
      if (emailFlag && phonFlag) {
        return true;
      } else {
        return false;
      }
    }
    if (submittedStepId === "region") {
      getlanguagePreferenceList();
      return true;
    }
    if (submittedStepId === "language") {
      profileDataSubmitHandler();
    }
  };

  const profileDataSubmitHandler = async () => {
    let refSignUpStates = JSON.parse(JSON.stringify(signUpStates));
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let featuresConfig = JSON.parse(
      window.sessionStorage.getItem("featuresConfig")
    );
    let refLanguage = JSON.parse(window.sessionStorage.getItem("Language"));
    let isCandidateDataSaved = false;

    setScreenLoader(true);
    const jsonData = {
      id: candidateID,
      firstName: refSignUpStates.firstName.value,
      lastName: refSignUpStates.lastName.value,
      preferredName: refSignUpStates.preferredName.value
        ? refSignUpStates.preferredName.value
        : "",
      emailAddress: refSignUpStates.primaryEmail.value,
      secondaryEmailAddress: refSignUpStates.alternativeEmail.value
        ? refSignUpStates.alternativeEmail.value
        : "",
      preferredContactNo: refSignUpStates.phone.value
        ? countryCode + " " + refSignUpStates.phone.value
        : "",
      preferredLanguageCode: refSignUpStates.languagePreference.value
        ? refSignUpStates.languagePreference.value
        : "",
      cityOfResidence: cityList.text,
      timeZone: refSignUpStates.timeZone.value
        ? refSignUpStates.timeZone.value
        : "",
      personaId: context.programDetails.personaId,
      rightOfficeId: selectedServiceOffice && selectedServiceOffice?.id,
      rightOfficeName: selectedServiceOffice && selectedServiceOffice?.value,
    };
    await OnBoardingActions.SaveCandisdateProfile(candidateID, jsonData).then(
      (res) => {
        if (res.status === 204) {
          isCandidateDataSaved = true;
          OnBoardingActions.GetFeaturesConfig().then(
            (res) => {
              if (res.status === 200) {
                window.sessionStorage.setItem(
                  "featuresConfig",
                  JSON.stringify(res?.data)
                );
              }
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );

    if (isCandidateDataSaved) {
      if (featuresConfig?.isOnboardingQuestionsAvailable) {
        if (
          refLanguage.selectedLanguageISOCode !==
          refSignUpStates.languagePreference.value
        ) {
          window.sessionStorage.setItem(
            "Language",
            JSON.stringify({
              selectedCountryFullName: refLanguage.selectedCountryFullName,
              selectedCountryShortName: refLanguage.selectedCountryShortName,
              selectedLanguageISOCode: refSignUpStates.languagePreference.value,
            })
          );
          sessionStorage.removeItem("headerCache");
          sessionStorage.removeItem("footerCache");
        }
        setTimeout(() => {
          navigate("/welcome-on-boarding");
          setScreenLoader(false);
        }, 500);
      } else {
        await OnBoardingActions.CandidateOnboarding({
          id: candidateID,
          isregistrationcompleted: "true",
          LoginReferenceCode:JSON.parse(
            window.sessionStorage.getItem("LoginCandidateToken")
          )["registrationCode"]
        }).then(
          (res) => {
            OnBoardingActions.GetOnBoardingStatus(candidateID).then(
              (res) => {
                if (res.status === 200) {
                  window.sessionStorage.setItem(
                    "onBoardingStatus",
                    JSON.stringify(res.data)
                  );
                }
              },
              (err) => {
                console.log(err);
              }
            );
          },
          (err) => {
            console.log(err);
          }
        );

        await OnBoardingActions.GetcandidateProfile(candidateID).then(
          (res) => {
            if (res.status === 200) {
              window.sessionStorage.setItem(
                "setProfileDetails",
                JSON.stringify(res?.data)
              );
              if (
                refLanguage.selectedLanguageISOCode !==
                refSignUpStates.languagePreference.value
              ) {
                window.sessionStorage.setItem(
                  "Language",
                  JSON.stringify({
                    selectedCountryFullName:
                      refLanguage.selectedCountryFullName,
                    selectedCountryShortName:
                      refLanguage.selectedCountryShortName,
                    selectedLanguageISOCode:
                      refSignUpStates.languagePreference.value,
                  })
                );
                sessionStorage.removeItem("headerCache");
                sessionStorage.removeItem("footerCache");
              }
              setTimeout(() => {
                navigate("/dashboard");
                setScreenLoader(false);
              }, 500);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  };

  const onChangeHandler = (event) => {
    let programDetails = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    const value = event.target.value;
    let suggestions = [];
    setCityInput(value);
    if (value === "") {
      setCityList({ suggestions, text: "", noData: false });
    }
    if (value.length > 0) {
      setCityList({ suggestions, text: value, noData: false });
    }
    if (value.length >= 3) {
      const satelliteCountryCode =
        programDetails.programSatelliteCountryCode != null
          ? programDetails.programSatelliteCountryCode
          : programDetails.programRightOfficeCountryCode;
      const inputTextCountryCode =
        satelliteCountryCode === "UK" ? "GB" : satelliteCountryCode;
      const payload = {
        field_to_query: "city",
        input_text: inputTextCountryCode + " " + value,
        country: satelliteCountryCode,
        language: programDetails.programLanguageCode,
      };
      OnBoardingActions.TKcityList(payload).then((res) => {
        res?.data?.auto_suggest?.map((item) => {
          let obj = {
            ID: item.item,
            Name: item.item,
            Value: item.item,
          };
          if (item?.item) {
            suggestions.push(obj);
          }
        });
        if (suggestions.length > 0) {
          setCityList({ suggestions, text: value, noData: false });
        } else {
          setCityList({ suggestions, text: value, noData: true });
        }
      });
    }
  };
  const selectItemHandler = (item) => {
    setCityInput(item.Name);
    setCityList({
      text: item.Name,
      suggestions: [],
      noData: false,
    });
  };

  const saveServiceOffice = () => {
    if (selectedServiceOffice) {
      setShowYourDetails(true);
      setShowServiceOffice(false);
      setIsErrorServiceOffice(false);
    } else {
      setIsErrorServiceOffice(true);
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      <div className="customLangHeaderWithBackButton">
        <LangHeader />
      </div>
      {serviceOffices && serviceOffices.length > 1 && showServiceOffice && (
        <Cirrus.OverlayedImageTwoColumnLayout
          containerHeading={cmsServiceOffice?.title}
          containerSubheading={cmsServiceOffice?.description}
          image={{
            altText:
              "Woman sat on sofa in a living room smiling at something on her laptop",
            src: cmsContent?.largeImage,
          }}
        >
          <div className="serviceOfficeOptions mb-3">
            {serviceOffices?.map((option, idx) => {
              return (
                <Cirrus.RadioButton
                  key={idx}
                  name="serviceOffice"
                  labelText={option.labelText}
                  value={option.value}
                  id={option.id}
                  removeBorder={false}
                  required
                  onChange={(e) => {
                    let chosen;
                    if (e.target.checked) {
                      chosen = {
                        value: option.value,
                        id: option.id,
                        programTimeZoneCode: option.timeZoneId,
                        programTimeZone: option.timeZone,
                      };
                    } else {
                    }
                    setSelectedServiceOffice(chosen);
                    updateSatelliteCountryCode(option.id);
                  }}
                />
              );
            })}
          </div>
          {isErrorServiceOffice && (
            <div className="mb-3">
              <Cirrus.ErrorMessage>Required field</Cirrus.ErrorMessage>
            </div>
          )}
          <div className="col-lg-7">
            <Cirrus.Button
              size="Large"
              type="submit"
              isFullWidth
              onClick={() => {
                saveServiceOffice();
              }}
            >
              {fetchLabel(
                "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.ContinueButton"
              )}
            </Cirrus.Button>
          </div>
        </Cirrus.OverlayedImageTwoColumnLayout>
      )}
      {showYourDetails && (
        <Cirrus.OverlayedImageTwoColumnLayout
          containerHeading={cmsContent.title}
          containerSubheading={cmsContent.description}
          image={{
            altText:
              "Woman sat on sofa in a living room smiling at something on her laptop",
            src: cmsContent?.largeImage,
          }}
        >
          <Cirrus.MultiStepForm
            continueButtonProps={{
              children: fetchLabel(
                "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.ContinueButton"
              ),
              size: "Large",
              type: "submit",
            }}
            onSubmit={onFormSubmit}
            progressBarLabel="Progress through registration"
          >
            <Cirrus.MultiStepForm.Step stepId="name" title="Name">
              <Cirrus.Input
                disabled
                id="FirstName"
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.FirstName"
                )}
                name={signUpStates.firstName.name}
                value={signUpStates.firstName.value}
              />
              <Cirrus.Input
                disabled
                id="LastName"
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.LastName"
                )}
                name={signUpStates.lastName.name}
                value={signUpStates.lastName.value}
              />
              <Cirrus.Input
                id="PreferredName"
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.PreferredName"
                )}
                name={signUpStates.preferredName.name}
                placeholder={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.PreferredNamePlaceholder"
                )}
                errorMessage={signUpStates.preferredName.errorMsg}
                state={signUpStates.preferredName.state}
                onChange={(event) => {
                  formHandler(event);
                }}
              />
            </Cirrus.MultiStepForm.Step>
            <Cirrus.MultiStepForm.Step stepId="contact" title="Contact details">
              <Cirrus.Input
                disabled
                id="Email"
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.RegisteredEmail"
                )}
                name={signUpStates.primaryEmail.name}
                value={signUpStates.primaryEmail.value}
              />
              <Cirrus.Input
                id="AltEmail"
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.AlternativeEmail"
                )}
                name={signUpStates.alternativeEmail.name}
                placeholder={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.AlternativeEmailPlaceHolder"
                )}
                errorMessage={signUpStates.alternativeEmail.errorMsg}
                state={signUpStates.alternativeEmail.state}
                onChange={(event) => {
                  formHandler(event);
                }}
              />
              <div>
                <Cirrus.Label>
                  {fetchLabel(
                    "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.Phone"
                  )}
                </Cirrus.Label>
                <div className="inpStyle">
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    value={countryCode}
                    onChange={setCountryCode}
                    className="countryCode"
                    aria-label={fetchLabel(
                      "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.Phone"
                    )}
                    autoComplete="new-password"
                  />
                  <input
                    className="phoneField"
                    id="Phone"
                    label={fetchLabel(
                      "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.Phone"
                    )}
                    name={signUpStates.phone.name}
                    placeholder="1234567890"
                    onChange={(event) => {
                      formHandler(event);
                    }}
                    maxLength={20}
                    autoComplete="new-password"
                  />
                </div>
                {signUpStates.phone.state && (
                  <Cirrus.ErrorMessage>
                    {signUpStates.phone.errorMsg}
                  </Cirrus.ErrorMessage>
                )}
              </div>
            </Cirrus.MultiStepForm.Step>
            <Cirrus.MultiStepForm.Step
              stepId="region"
              title="Regional detrails"
            >
              <div className="autocomplete_city">
                <AutoComplete
                  inputId="City"
                  inputLabel={fetchLabel(
                    "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.CityofResidence"
                  )}
                  inputName={signUpStates.cityOfResidence.name}
                  inputValue={cityInput}
                  onChangeHandler={onChangeHandler}
                  suggestionsItem={cityList}
                  inputPlaceholder={fetchLabel(
                    "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.SelectCity"
                  )}
                  selectItemHandler={selectItemHandler}
                  noDataFound={cityList.noData}
                />
              </div>
              <Cirrus.Select
                id="TimeZone"
                defaultValue={signUpStates.timeZone.value}
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.TimeZone"
                )}
                name={signUpStates.timeZone.name}
                options={timeZoneList}
                placeholder={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.SelectTimeZone"
                )}
                onChange={(event) => {
                  formHandler(event);
                }}
              />
            </Cirrus.MultiStepForm.Step>
            <Cirrus.MultiStepForm.Step
              stepId="language"
              title="Language preferences"
            >
              <Cirrus.Select
                id="Language"
                defaultValue={signUpStates.languagePreference.value}
                label={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.LanguagePreference"
                )}
                name={signUpStates.languagePreference.name}
                options={languagePreferenceList}
                placeholder={fetchLabel(
                  "SignUpConfirmDetails.OnboardingSignUpConfirmDetails.SelectLanguage"
                )}
                onChange={(event) => {
                  formHandler(event);
                }}
              />
            </Cirrus.MultiStepForm.Step>
          </Cirrus.MultiStepForm>
        </Cirrus.OverlayedImageTwoColumnLayout>
      )}
    </>
  );
}

export default ConfirmDetails;
