//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useEffect, useState, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { coachActions } from "../coach/coachActions";
import MeetingRescheduleDialog from "../coach/MeetingRescheduleDailog";
import { EventsActions } from "../myEvents/EventsActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Context } from "../../common/context/Context";
import { CandidateCalenderActions } from "./CandidateCalenderAction";
import {
  globalTimeFormat,
  globalDateFormat,
} from "../../common/helpers/DateTimeHelper";

function CandidateCalenderEventDetails({
  fetchCMSLabel,
  cmsContent,
  eventData,
  closeDialog,
  ReloadComp,
  allEventsData,
}) {
  const context = useContext(Context);
  const [sCoachName, setsCoachName] = useState("");
  const [sInvitiesName, setsInvitiesName] = useState("");
  const [mycoachData, setmycoachData] = useState("");
  const [cmsCoachContent, setcmsCoachContent] = useState({
    cancelMeeting_Labels: [],
    cancelMeeting_Title: "",
    cancelMeeting_Description: "",
    meetingSchedule_Title: "",
    meetingSchedule_Labels: [],
    meetingReschedule_Title: "",
    meetingReschedule_Labels: [],
  });
  const [displayMeetingCancel, setdisplayMeetingCancel] = useState(false);
  const [displayMeetingReschedule, setdisplayMeetingReschedule] =
    useState(false);
  const [
    closeMeetingRescheduleConfirmation,
    setcloseMeetingRescheduleConfirmation,
  ] = useState(false);
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setViewPortWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const getMyCoach = async () => {
    await coachActions
      .GetMyCoach()
      .then((res) => {
        if (res) {
          setsCoachName(res.data?.firstName + " " + res.data?.lastName);
          setmycoachData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCMSContent = async () => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let payload = {
      PageName: "MyCoach",
    };
    await coachActions.GetCMSJobLabels(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          resData.Pages.filter((x) => {
            if (x.Key === "MyCoachPage") {
              refCmsContent.cancelMeeting_Labels =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;

              refCmsContent.cancelMeeting_Title =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Title;

              refCmsContent.cancelMeeting_Description =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Description;

              refCmsContent.meetingSchedule_Title =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Title;

              refCmsContent.meetingSchedule_Labels =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

              refCmsContent.meetingReschedule_Title =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Title;

              refCmsContent.meetingReschedule_Labels =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Labels;

              refCmsContent.CommonLabels =
                x.Components["CommonComponent"]?.Labels;
            }
          });
          setcmsCoachContent(refCmsContent);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchLabel = (key, section) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsCoachContent));
    let label = "";
    if (refCmsContent?.[section]?.length > 0) {
      refCmsContent[section].map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fnOpenCancelRescheduleWindow = (nflag) => {
    if (nflag === 0) {
      setdisplayMeetingCancel(true);
      setdisplayMeetingReschedule(false);
    } else {
      setdisplayMeetingReschedule(true);
      setdisplayMeetingCancel(false);
    }
  };

  const closeCancelDialog = (nflag) => {
    if (nflag === 0) {
      setdisplayMeetingCancel(false);
      setdisplayMeetingReschedule(false);
    } else {
      setdisplayMeetingReschedule(false);
      setdisplayMeetingCancel(false);
    }
  };

  const fnSaveCancelMeeting = async () => {
    await coachActions.DeleteMeeting(eventData.id).then(async (res) => {
      if (res.status === 200) {
        ReloadComp((prev) => prev + 1);
        closeDialog(false);
      } else {
        closeDialog(false);
      }
    });
  };

  const fnCloseRescheduleDailog = (nflag, prescheduleTime, prescheduleDate) => {
    if (prescheduleTime !== "" || prescheduleDate !== "") {
      setcloseMeetingRescheduleConfirmation(true);
    } else {
      setdisplayMeetingReschedule(nflag);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("CoachDetails") != null) {
      let objCoachDetails = JSON.parse(
        window.sessionStorage.getItem("CoachDetails")
      );
      setsCoachName(objCoachDetails.firstName + " " + objCoachDetails.lastName);
      setmycoachData(JSON.parse(window.sessionStorage.getItem("CoachDetails")));
    } else {
      getMyCoach();
    }
    setsInvitiesName(
      JSON.parse(sessionStorage.getItem("setIdTokenClaims")).firstName +
        " " +
        JSON.parse(sessionStorage.getItem("setIdTokenClaims")).lastName
    );
  }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("CoachCMS") != null) {
      let objCoachCMS = JSON.parse(window.sessionStorage.getItem("CoachCMS"));
      let objeCMS = {};
      objeCMS["cancelMeeting_Labels"] = objCoachCMS.cancelMeeting_Labels;
      objeCMS["cancelMeeting_Title"] = objCoachCMS.cancelMeeting_Title;
      objeCMS["cancelMeeting_Description"] =
        objCoachCMS.cancelMeeting_Description;
      objeCMS["meetingSchedule_Title"] = objCoachCMS.meetingSchedule_Title;
      objeCMS["meetingSchedule_Labels"] = objCoachCMS.meetingSchedule_Labels;
      objeCMS["meetingReschedule_Title"] = objCoachCMS.meetingReschedule_Title;
      objeCMS["meetingReschedule_Labels"] =
        objCoachCMS.meetingReschedule_Labels;
      setcmsCoachContent(objeCMS);
    } else {
      fetchCMSContent();
    }
  }, [context?.language]);

  const fnfetchDomain = (sURL) => {
    try {
      const url = new URL(sURL.replace(/ /g, ""));
      return url.hostname;
    } catch (e) {
      return false;
    }
  };

  const isEventTime = (sessionDate) => {
    let event = allEventsData.find((event) => {
      if (event.meetingId === eventData.extendedProps.meetingId) {
        return event.duration;
      }
    });
    let eventDuration = event.duration;
    const utcDate = Date.parse(new Date());
    const sessionUTCdate = Date.parse(sessionDate);
    let time_difference = utcDate - sessionUTCdate;
    let minute_difference = time_difference / 1000 / 60;
    if (Math.round(minute_difference) <= -15) {
      return true;
    } else {
      if (
        Math.round(minute_difference) >= -15 &&
        Math.round(minute_difference) >= parseInt(eventDuration)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const doceboJoinMeeting = async (meetingId, type, title, SessionId) => {
    let res = await EventsActions.GetDoceboSessionLink();
    let url = res.data + "&id_course=" + meetingId;
    let payload = {
      candidateId: JSON.parse(
        window.sessionStorage.getItem("setIdTokenClaims")
      )["signupId"],
      itemId: meetingId,
      type: type,
      value: title ? title : "null",
    };
    CandidateCalenderActions.CaptureActivity(payload).then(
      (res) => {
        if (res.status === 200) {
        } else {
          console.log("not success");
        }
      },
      (err) => {
        console.log(err);
      }
    );
    // Docebo Attendance Marking
    const IsAttended = true;
    EventsActions.MarkAttendance({
      CourseId: meetingId,
      IsAttended,
      SessionId: SessionId,
    }).then(
      (res) => {
        if (res.status === 200) {
        } else {
          console.log("Attendance failed");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    window.open(url);
  };

  const cronofyJoinMeeting = (meetingURL) => {
    window.open(meetingURL, "_blank");
  };

  return (
    <>
      <div
        style={
          displayMeetingCancel === false && displayMeetingReschedule === false
            ? { display: "block", width: "100%" }
            : { display: "none" }
        }
      >
        {eventData.extendedProps.location.indexOf("docebosaas") == -1 &&
        fnfetchDomain(eventData.extendedProps.location) !== false ? (
          <Cirrus.Modal
            className="meetingConfirmModal meeting"
            closeButtonProps={{
              leadingIcon: "close",
              isSecondary: false,
              onClick: () => closeDialog(false),
              children: "Close",
              type: "button",
            }}
            closeIconButtonProps={{
              icon: "close",
              isSecondary: true,
              onClick: () => closeDialog(false),
              text: "Cancel",
              type: "button",
            }}
            headerProps={{
              children: cmsContent.EventDetails_Title,
              className: "no-margin",
              size: "h5",
            }}
            isScrollable
            role="dialog"
            size="mobile"
          >
            <h2>{eventData.title}</h2>
            <Cirrus.Label>
              <div>{globalDateFormat(eventData.startStr)}</div>
              <div>
                {globalTimeFormat(eventData.startStr)} -{" "}
                {globalTimeFormat(eventData.endStr)}
              </div>
            </Cirrus.Label>
            <br />
            <br />
            <Cirrus.Label>
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.InviteesText",
                cmsContent.EventDetails_Lables
              )}
              :
              <br />
              <i className="icon-person" /> &nbsp; {sInvitiesName} (You)
              <br />
              <i className="icon-person" /> &nbsp; {sCoachName} (Coach)
              {viewPortWidth <= 768 && mycoachData?.primaryContactNo && (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  {mycoachData?.primaryContactNo &&
                    mycoachData?.primaryContactNo}
                </>
              )}
            </Cirrus.Label>
            <br /> <br />
            <Cirrus.Label>
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.Location",
                cmsContent.EventDetails_Lables
              )}
              : <br />{" "}
              {fnfetchDomain(eventData.extendedProps.location) == false
                ? eventData.extendedProps.location
                : fnfetchDomain(eventData.extendedProps.location)}
            </Cirrus.Label>
            <br /> <br />
            <Cirrus.Label>
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.NotesText",
                cmsContent.EventDetails_Lables
              )}
              :
              <br /> {eventData.extendedProps.title}
            </Cirrus.Label>
            <br /> <br />
            <Cirrus.Button
              size="Large"
              onClick={() => {
                cronofyJoinMeeting(eventData?.extendedProps?.location);
              }}
              isFullWidth={true}
            >
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.JoinMeetingButton",
                cmsContent.EventDetails_Lables
              )}
            </Cirrus.Button>
            <br /> <br />
            <Cirrus.Label>
              <span className="font-color-secondary">
                {fetchCMSLabel(
                  "CalendarPage.ViewEventModal.Description",
                  cmsContent.EventDetails_Lables
                )
                  .toString()
                  .replace(
                    "#platform-name#",
                    fnfetchDomain(eventData.extendedProps.location) == false
                      ? eventData.extendedProps.location
                      : fnfetchDomain(eventData.extendedProps.location)
                  )}
              </span>
            </Cirrus.Label>
            <br /> <br />
            <div style={{ display: "flex" }}>
              <Container>
                <Row>
                  <Col className="mb-2">
                    <Cirrus.Button
                      size="Large"
                      onClick={() => fnOpenCancelRescheduleWindow(1)}
                      isFullWidth={true}
                      isSecondary={true}
                    >
                      {fetchCMSLabel(
                        "CalendarPage.ViewEventModal.RescheduleButton",
                        cmsContent.EventDetails_Lables
                      )}
                    </Cirrus.Button>
                  </Col>
                  <Col>
                    <Cirrus.Button
                      size="Large"
                      disabled={false}
                      onClick={() => fnOpenCancelRescheduleWindow(0)}
                      isFullWidth={true}
                      isSecondary={true}
                      className="text-nowrap"
                    >
                      {fetchCMSLabel(
                        "CalendarPage.ViewEventModal.CancelMeetingButton",
                        cmsContent.EventDetails_Lables
                      )}
                    </Cirrus.Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Cirrus.Modal>
        ) : (
          <Cirrus.Modal
            className="meetingConfirmModal event"
            closeButtonProps={{
              leadingIcon: "close",
              isSecondary: false,
              onClick: () => closeDialog(false),
              children: "Close",
              type: "button",
            }}
            closeIconButtonProps={{
              icon: "close",
              isSecondary: false,
              onClick: () => closeDialog(false),
              text: "Cancel",
              type: "button",
            }}
            headerProps={{
              children: cmsContent.EventDetails_Title,
              className: "no-margin",
              size: "h5",
            }}
            isScrollable
            role="dialog"
            size="mobile"
          >
            <h2>{eventData.title}</h2>
            <Cirrus.Label>
              <div>{globalDateFormat(eventData.startStr)}</div>
              <div>
                {globalTimeFormat(eventData.startStr)} -{" "}
                {globalTimeFormat(eventData.endStr)}
              </div>
            </Cirrus.Label>
            <br />
            <br />
            <Cirrus.Label>
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.Location",
                cmsContent.EventDetails_Lables
              )}
              : <br />{" "}
              {fnfetchDomain(eventData.extendedProps.location) == false
                ? eventData.extendedProps.location
                : fnfetchDomain(eventData.extendedProps.location)}
            </Cirrus.Label>
            <br /> <br />
            <Cirrus.Button
              size="Large"
              onClick={() => {
                doceboJoinMeeting(
                  eventData?.extendedProps?.meetingId,
                  "DoceboEventJoinClick",
                  "",
                  eventData?.extendedProps?.SessionId
                );
              }}
              isFullWidth={true}
              disabled={isEventTime(eventData.startStr)}
            >
              {fetchCMSLabel(
                "CalendarPage.ViewEventModal.JoinMeetingButton",
                cmsContent.EventDetails_Lables
              )}
            </Cirrus.Button>
            <br /> <br />
            <div style={{ display: "flex" }}></div>
          </Cirrus.Modal>
        )}
      </div>
      <div
        style={
          displayMeetingCancel === false
            ? { display: "none", width: "100%" }
            : { display: "block" }
        }
      >
        <Cirrus.AlertModal
          className="CancelMeeting"
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () => closeCancelDialog(0),
            text: "Cancel",
            type: "button",
          }}
          heading={cmsCoachContent.cancelMeeting_Title}
          primaryButton={{
            children: fetchLabel(
              "MyCoachPage.MyCoachCancelMeeting.CancelMeeting",
              "cancelMeeting_Labels"
            ),
            onClick: () => fnSaveCancelMeeting(),
            size: "Large",
            type: "button",
          }}
          role="alertdialog"
          subheading={cmsCoachContent?.cancelMeeting_Description}
        />
      </div>

      <div
        style={
          displayMeetingReschedule === false
            ? { display: "none", width: "100%" }
            : { display: "block" }
        }
      >
        {displayMeetingReschedule && (
          <MeetingRescheduleDialog
            fetchLabel={fetchLabel}
            cmsContent={cmsCoachContent}
            mycoachData={mycoachData}
            dialogStatus={setdisplayMeetingReschedule}
            closeDialog={fnCloseRescheduleDailog}
            confirmDialogStatus={closeMeetingRescheduleConfirmation}
            closeconfirmDialog={setcloseMeetingRescheduleConfirmation}
            rescheduleMeetingData={{
              Title: eventData.title,
              id: eventData.id,
              startDate: eventData.start,
              endDate: eventData.end,
            }}
            ReloadComp={ReloadComp}
            todayButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.TodayButton",
              "meetingSchedule_Labels"
            )}
            nextButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.NextButton",
              "meetingSchedule_Labels"
            )}
            backButtonLabel={fetchLabel(
              "CommonComponent.CommonBackButton",
              "CommonLabels"
            )}
          ></MeetingRescheduleDialog>
        )}
      </div>
    </>
  );
}

export default CandidateCalenderEventDetails;
