//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useEffect, useState, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { coachActions } from "./coachActions";
import MeetingRescheduleDialog from "./MeetingRescheduleDailog";
import { EventsActions } from "../myEvents/EventsActions";
import { Context } from "../../common/context/Context";
import { CandidateCalenderActions } from "../calendar/CandidateCalenderAction";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import {
  globalTimeFormat,
  globalDateFormat,
} from "../../common/helpers/DateTimeHelper";

function UpcomigEventDetails({
  typeIsNotEvent = true,
  mycoachData,
  cmsContentCoach,
  cmsCoachContent,
  calledFrom,
  eventData,
  fetchLabelCoach,
  fetchLabel,
  closeDialog,
  ReloadComp,
  disableButtons,
}) {
  const context = useContext(Context);
  const [sCoachName, setsCoachName] = useState("");
  const appInsights = useAppInsightsContext();
  const [sInvitiesName, setsInvitiesName] = useState("");
  const [cmsCalendarContent, setcmsCalendarContent] = useState({
    title: "",
    description: "",
    labels: [],
    EventDetails_Title: "",
    EventDetails_Lables: [],
  });
  const [displayMeetingCancel, setdisplayMeetingCancel] = useState(false);
  const [displayMeetingReschedule, setdisplayMeetingReschedule] =
    useState(false);
  const [
    closeMeetingRescheduleConfirmation,
    setcloseMeetingRescheduleConfirmation,
  ] = useState(false);
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setViewPortWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const fnFetchCalendarDataFromCMS = async () => {
    try {
      let cmscalenderpayload = {
        PageName: "Calendar",
      };

      const res = await CandidateCalenderActions.GetCMSForCandidateCalender(
        cmscalenderpayload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fnLoadCalendarCSMData = async () => {
    let objCMSData = fnFetchCalendarDataFromCMS();
    objCMSData.then((resData) => {
      if (Object.keys(resData).length > 0) {
        let refcmsCalendarPageTemplate = JSON.parse(
          JSON.stringify(cmsCalendarContent)
        );
        resData.Pages?.filter((x) => {
          if (x.Key === "Calendar") {
            let compName = x.Components["CalendarPage.CalendarPageTemplate"];
            refcmsCalendarPageTemplate.title = compName.Title;
            refcmsCalendarPageTemplate.description = compName.Description;
            refcmsCalendarPageTemplate.labels = compName.Labels;

            let EventcomName = x.Components["CalendarPage.ViewEventModal"];
            refcmsCalendarPageTemplate.EventDetails_Title = EventcomName.Title;
            refcmsCalendarPageTemplate.EventDetails_Lables =
              EventcomName.Labels;
          }
        });
        setcmsCalendarContent(refcmsCalendarPageTemplate);
        window.sessionStorage.setItem(
          "CalendarCMS",
          JSON.stringify(refcmsCalendarPageTemplate)
        );
      }
    });
  };

  const fetchCalendarCMSLabel = (key, ssection) => {
    let label = "";
    if (ssection != undefined) {
      let refCmsContent = ssection;
      if (refCmsContent) {
        refCmsContent.map((x) => {
          if (x.Key === key) {
            label = x.Phrase;
          }
        });
      }
    }
    return label;
  };

  const fnOpenCancelRescheduleWindow = (nflag) => {
    if (nflag === 0) {
      setdisplayMeetingCancel(true);
      setdisplayMeetingReschedule(false);
    } else {
      setdisplayMeetingReschedule(true);
      setdisplayMeetingCancel(false);
    }
  };

  const closeCancelDialog = (nflag) => {
    if (nflag === 0) {
      setdisplayMeetingCancel(false);
      setdisplayMeetingReschedule(false);
    } else {
      setdisplayMeetingReschedule(false);
      setdisplayMeetingCancel(false);
    }
  };

  const fnSaveCancelMeeting = async () => {
    await coachActions.DeleteMeeting(eventData.id).then(async (res) => {
      TrackActions.EventTrack(appInsights, "CancelEvent", {
        EventID: eventData.id,
      });
      TrackActions.PageAction("CancelEvent", { EventID: eventData.id });
      if (res.status === 200) {
        ReloadComp((prev) => prev + 1);
        closeDialog(false);
      } else {
        closeDialog(false);
      }
    });
  };

  const fnCloseRescheduleDailog = (nflag, prescheduleTime, prescheduleDate) => {
    if (prescheduleTime !== "" || prescheduleDate !== "") {
      setcloseMeetingRescheduleConfirmation(true);
    } else {
      setdisplayMeetingReschedule(nflag);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("CalendarCMS") != null) {
      let objCalendarCMS = JSON.parse(
        window.sessionStorage.getItem("CalendarCMS")
      );
      setcmsCalendarContent(objCalendarCMS);
    } else {
      fnLoadCalendarCSMData();
    }
    setsInvitiesName(
      JSON.parse(sessionStorage.getItem("setIdTokenClaims")).firstName +
        " " +
        JSON.parse(sessionStorage.getItem("setIdTokenClaims")).lastName
    );
    setsCoachName(mycoachData.firstName + " " + mycoachData.lastName);
  }, [context?.language]);

  const fnfetchDomain = (sURL) => {
    try {
      const url = new URL(sURL.replace(/ /g, ""));
      return url.hostname;
    } catch (e) {
      typeIsNotEvent = false;
      return false;
    }
  };

  const isEventTime = (sessionDate, duration) => {
    const utcDate = Date.parse(new Date());
    const sessionUTCdate = Date.parse(sessionDate);
    let time_difference = utcDate - sessionUTCdate;
    let minute_difference = time_difference / 1000 / 60;
    if (Math.round(minute_difference) <= -15) {
      return true;
    } else {
      if (
        Math.round(minute_difference) >= -15 &&
        Math.round(minute_difference) >= parseInt(duration)
      ) {
        console.log("1");
        return true;
      } else {
        return false;
      }
    }
  };

  const doceboJoinMeeting = async (meetingId, SessionId) => {
    let res = await EventsActions.GetDoceboSessionLink();
    let url = res.data + "&id_course=" + meetingId;

    // Docebo Attendance Marking
    const IsAttended = true;
    EventsActions.MarkAttendance({
      CourseId: meetingId,
      IsAttended,
      SessionId: SessionId,
    }).then(
      (res) => {
        if (res.status === 200) {
        } else {
          console.log("Attendance failed");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    window.open(url);
  };

  const cronofyJoinMeeting = (meetingURL) => {
    window.open(meetingURL, "_blank");
  };

  return (
    <>
      <div
        style={
          displayMeetingCancel === false && displayMeetingReschedule === false
            ? { display: "block", width: "100%" }
            : { display: "none" }
        }
      >
        <Cirrus.Modal
          className="meetingConfirmModal upcoming-meeting"
          closeButtonProps={{
            leadingIcon: "close",
            isSecondary: false,
            onClick: () => closeDialog(false),
            children: "Close",
            type: "button",
          }}
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () => closeDialog(false),
            text: "Cancel",
            type: "button",
          }}
          headerProps={{
            children: cmsCalendarContent.EventDetails_Title,
            className: "no-margin",
            size: "h5",
          }}
          isScrollable
          role="dialog"
          size="mobile"
        >
          <h2 className="upcoming-meeting-title">{eventData.title}</h2>
          <div className="mb-3">
            <Cirrus.Label>
              <div>{globalDateFormat(eventData.startTimeStamp)}</div>
              <div>
                {globalTimeFormat(eventData.startTimeStamp)} -{" "}
                {globalTimeFormat(eventData.endTimeStamp)}
              </div>
            </Cirrus.Label>
          </div>
          {typeIsNotEvent && (
            <div className="mb-3">
              <Cirrus.Label>
                {fetchCalendarCMSLabel(
                  "CalendarPage.ViewEventModal.InviteesText",
                  cmsCalendarContent.EventDetails_Lables
                )}
                :
                <br />
                <i className="icon-person" /> &nbsp; {sInvitiesName}
                {/* <br /><i className="icon-person" />  &nbsp; {sCoachName} (Coach) */}
                {viewPortWidth <= 768 && mycoachData?.primaryContactNo && (
                  <>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    {mycoachData?.primaryContactNo &&
                      mycoachData?.primaryContactNo}
                  </>
                )}
              </Cirrus.Label>
            </div>
          )}
          <div className="mb-2">
            <Cirrus.Label>
              {fetchCalendarCMSLabel(
                "CalendarPage.ViewEventModal.Location",
                cmsCalendarContent.EventDetails_Lables
              )}
              : <br />
              {fnfetchDomain(eventData.location) == false
                ? eventData.location
                : fnfetchDomain(eventData.location)}
            </Cirrus.Label>
          </div>
          {typeIsNotEvent && (
            <div className="mb-3">
              <Cirrus.Label>
                {fetchCalendarCMSLabel(
                  "CalendarPage.ViewEventModal.NotesText",
                  cmsCalendarContent.EventDetails_Lables
                )}
                :{/* <br /> {eventData.extendedProps.title} */}
              </Cirrus.Label>
            </div>
          )}
          <div className="mb-2">
            <Cirrus.Button
              size="Large"
              onClick={() => {
                (eventData?.location).includes("docebosaas")
                  ? doceboJoinMeeting(
                      eventData?.meetingId,
                      eventData?.sessionID
                    )
                  : cronofyJoinMeeting(eventData?.location);
              }}
              isFullWidth={true}
              // disabled={disableButtons}
              disabled={
                typeIsNotEvent
                  ? disableButtons
                  : isEventTime(eventData.startTimeStamp, eventData.duration)
              }
            >
              {fetchCalendarCMSLabel(
                "CalendarPage.ViewEventModal.JoinMeetingButton",
                cmsCalendarContent.EventDetails_Lables
              )}
            </Cirrus.Button>
          </div>
          <div className="mb-3" style={{ fontSize: "14px" }}>
            {/* <Cirrus.Label> */}
            {fetchCalendarCMSLabel(
              "CalendarPage.ViewEventModal.Description",
              cmsCalendarContent.EventDetails_Lables
            )
              .toString()
              .replace(
                "#platform-name#",
                fnfetchDomain(eventData.location) == false
                  ? eventData.location
                  : fnfetchDomain(eventData.location)
              )}
            {/* </Cirrus.Label> */}
          </div>
          {typeIsNotEvent && (
            <div style={{ display: "flex", gap: "10px" }}>
              <Cirrus.Button
                size="Small"
                onClick={() => fnOpenCancelRescheduleWindow(1)}
                isFullWidth={true}
                isSecondary={true}
                disabled={disableButtons}
              >
                {fetchCalendarCMSLabel(
                  "CalendarPage.ViewEventModal.RescheduleButton",
                  cmsCalendarContent.EventDetails_Lables
                )}
              </Cirrus.Button>
              <Cirrus.Button
                size="Small"
                disabled={disableButtons}
                onClick={() => fnOpenCancelRescheduleWindow(0)}
                isFullWidth={true}
                isSecondary={true}
                className="text-nowrap"
              >
                {fetchCalendarCMSLabel(
                  "CalendarPage.ViewEventModal.CancelMeetingButton",
                  cmsCalendarContent.EventDetails_Lables
                )}
              </Cirrus.Button>
            </div>
          )}
        </Cirrus.Modal>
      </div>
      <div
        style={
          displayMeetingCancel === false
            ? { display: "none", width: "100%" }
            : { display: "block" }
        }
      >
        <Cirrus.AlertModal
          className="CancelMeeting"
          closeIconButtonProps={{
            icon: "close",
            isSecondary: true,
            onClick: () => closeCancelDialog(0),
            text: "Cancel",
            type: "button",
          }}
          heading={
            cmsContentCoach
              ? cmsContentCoach?.cancelMeeting_Title
              : cmsCoachContent?.cancelMeeting_Title
          }
          primaryButton={{
            children: fetchLabel(
              "MyCoachPage.MyCoachCancelMeeting.CancelMeeting",
              "cancelMeeting_Labels"
            ),
            onClick: () => fnSaveCancelMeeting(),
            size: "Large",
            type: "button",
          }}
          role="alertdialog"
          subheading={
            cmsContentCoach
              ? cmsContentCoach?.cancelMeeting_Description
              : cmsCoachContent?.cancelMeeting_Description
          }
        />
      </div>

      <div
        style={
          displayMeetingReschedule === false
            ? { display: "none", width: "100%" }
            : { display: "block" }
        }
      >
        {displayMeetingReschedule && (
          <MeetingRescheduleDialog
            fetchLabel={
              calledFrom == "dashboard" ? fetchLabelCoach : fetchLabel
            }
            cmsContent={
              calledFrom == "dashboard" ? cmsContentCoach : cmsCoachContent
            }
            mycoachData={mycoachData}
            dialogStatus={setdisplayMeetingReschedule}
            closeDialog={fnCloseRescheduleDailog}
            confirmDialogStatus={closeMeetingRescheduleConfirmation}
            closeconfirmDialog={setcloseMeetingRescheduleConfirmation}
            rescheduleMeetingData={{
              Title: eventData.title,
              id: eventData.id,
              startDate: eventData.startTimeStamp,
              endDate: eventData.endTimeStamp,
            }}
            ReloadComp={ReloadComp}
            todayButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.TodayButton",
              "meetingSchedule_Labels"
            )}
            nextButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.NextButton",
              "meetingSchedule_Labels"
            )}
            backButtonLabel={fetchLabel(
              "CommonComponent.CommonBackButton",
              "commonComponents_Labels"
            )}
          ></MeetingRescheduleDialog>
        )}
      </div>
    </>
  );
}

export default UpcomigEventDetails;
