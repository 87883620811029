import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "../../../common/helpers/AuthService";

function Directlogin() {
  const { instance, accounts, inProgress } = useMsal();

  let auth = {
    token: sessionStorage.getItem("setIdTokenClaims") ? true : false,
  };

  const redirectingADB2C = () => {
    console.log("asdfasdfa");
    sessionStorage.removeItem("acquireTokenRedirectFlag");
    sessionStorage.removeItem("AccessToken");
    sessionStorage.removeItem("setIdTokenClaims");
    instance.loginRedirect(loginRequest);
  };
  return auth.token ? (
    <>
      <Navigate to="/dashboard" />
    </>
  ) : (
    <>{redirectingADB2C()}</>
  );
}

export default Directlogin;
